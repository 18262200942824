import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Application from 'components/Application';

export default props => {
  let { contentfulTool, allContentfulTool } = props.data;
  console.log(props);
  if (!contentfulTool) return 'No application page';

  return (
    <Layout>
      <Application
        page={contentfulTool}
        otherApplications={allContentfulTool.nodes || null}
      />
    </Layout>
  );
};

export const query = graphql`
  query($contentful_id: String!) {
    contentfulTool(contentful_id: { eq: $contentful_id }) {
      ...ContentfulToolFields
    }
    allContentfulTool(
      filter: {
        contentful_id: { ne: $contentful_id }
        type: { eq: "Application card" }
      }
    ) {
      nodes {
        title
        contentful_id
        image {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
