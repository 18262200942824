import React from 'react';
import get from 'lodash.get';

import urls from 'helpers/url';

import {
  Spacing,
  SharedStyles,
  Container,
  SliderColorMelt,
  Slider,
  VideoWithInfo,
  ListOfImg,
} from '@matchbox-mobile/kenra-storybook';
import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';

const {
  StSectionInfo,
  StTitle,
  StSectionMobileViewMore,
  StSectionTitle,
  StLink,
} = SharedStyles;
const { StSliderColorMelt } = SliderColorMelt;

function formatFormula(applicationFormula) {
  if (!applicationFormula) {
    return null;
  }
  let formattedText = '<h3>Formula</h3><p>';
  applicationFormula.forEach((item, index) => {
    formattedText += item;
    if (index < applicationFormula.length - 1) {
      formattedText += '<br />';
    }
  });
  formattedText += '</p>';
  return formattedText;
}

export default function Application({ page, otherApplications }) {
  const {
    applicationVideo,
    applicationFormula,
    applicationStepsTitle,
    applicationSteps,
    applicationTitle,
    applicationSubtitle,
  } = page;
  let youTubeId = null;
  if (applicationVideo && applicationVideo.youtubeLink) {
    youTubeId = getYouTubeId(applicationVideo.youtubeLink);
  }
  const videoOverlayText = formatFormula(applicationFormula);

  return (
    <>
      <Spacing removeSpaceBottom>
        <StSectionInfo>
          <Container>
            <StTitle>
              <h1>{applicationTitle}</h1>
            </StTitle>
            <p>{applicationSubtitle}</p>
          </Container>
        </StSectionInfo>
      </Spacing>

      {youTubeId && (
        <Spacing>
          <VideoWithInfo
            img={getPreviewUrl(youTubeId)}
            useImgOverlay
            text={videoOverlayText}
            textStyle={{ lineHeight: 2.4 }}
            buttonText={'Download PDF'}
            target="_blank"
            rel="noopener noreferrer"
            href={get(page, 'file.localFile.publicURL')}
            videoType="youtube"
            autoPlay
            videoOptions={[{ src: getEmbedUrl(youTubeId) }]}
          />
        </Spacing>
      )}

      {applicationSteps && applicationSteps.length > 0 && (
        <Spacing>
          <StSectionMobileViewMore>
            <StSectionTitle>
              <Container>
                <h2>{applicationStepsTitle}</h2>
                {/* <StLink mobileStyleLinkMore>
                  <a href="/">SMS PDF</a>
                </StLink> */}
              </Container>
            </StSectionTitle>
            <Slider
              settings={{
                slidesPerView: 'auto',
              }}
            >
              {applicationSteps &&
                applicationSteps.map((step, index) => {
                  const { imageUrl, stepText } = step;
                  return (
                    <StSliderColorMelt key={index}>
                      <img src={imageUrl} alt="" />
                      <h3>{`Step ${index + 1}`}</h3>
                      <p>{stepText}</p>
                    </StSliderColorMelt>
                  );
                })}
            </Slider>
          </StSectionMobileViewMore>
        </Spacing>
      )}

      {otherApplications && otherApplications.length > 0 && (
        <Spacing>
          <section>
            <StSectionTitle>
              <Container>
                <h2>GET THE LOOK</h2>
              </Container>
            </StSectionTitle>
            <ListOfImg
              btnMoreHref={urls.applications}
              items={otherApplications.map(application => ({
                title: application.title,
                img: get(application, 'image.localFile.publicURL'),
                styleWidth50: false,
                href: `/application/${application.title}`,
              }))}
            />
          </section>
        </Spacing>
      )}
    </>
  );
}
